import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Creating docs",
  "description": "Learn how to create docs using Rocket Docs."
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "introduction",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#introduction",
        "aria-label": "introduction permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Introduction`}</h1>
    <p>{`Manage your team members progress & produce excellent and clear visuals for your Product Owners`}</p>
    <hr></hr>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "861px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "88.33333333333333%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAYAAABb0P4QAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEG0lEQVQ4y31US4gcRRguZ/WgePa+ggdz0FsIqCcvXjx49KISDZkEX6csCMlFAslBIYL3HJb1EDBkUdloMKDxgZhknJ1sdnZ6e7p7Z2anX1Pd9X51/1JjPETFgo+f6ur6/+9/fIXSdP4RxvhqhRfrhJANQsh6XdfXyqI8vrszeC3N0mtZlq+nabpRluVGURQPIc/zjfk8XZ/P06tJkryOKKU/S6mgBYCmaUBrBX5prS/P5/PzVVUB5xystQ/BeTi3xN/fKKWXkJJyS0sOujwQSnJtjBVt24Bg5HMuxFnGKEillBLMaqWssdYqpS0XwkqlLWXMCiGUFAIwxhc8wx+lFFBmM6gwBs9IcO4PLzNKz2mtQSllrdFt4yw0VkPjDDgtwbAFaCVbrZW1kgGryosoCIJX4iQ5FUbJW+MoejMMwzfiOOoOhsPnaJ6d5xUGIaVtAVqezWF64yuY3tyCydaXkA9+B2N0qwS3SgqoFsVFxBhD1loEAP9CVtWXcE2AEmKsc41Tqkmms+b6dtDcvT9sZJU3QohGCm58lrjMLyAp1WOMC1Tk2ceEEMsZy4XgCyVEUdSY15yCrw80LexUE9ia9uDXLAAhKVhFwFoFxiifOtS4/BQ5a1YAWsQo/YRSAoySpm2c7zmk8wwmB7NpVVU7jLFhhsvgEB8Gt29HwdaVneCbK4Ng84ud4LcfgiFj1Xw+m6751FZ8epyzzzyTJZsHy49Rkhyc8uen3vvg8f8qyz+B8GLRUUqjJEneLori+wrjTULI14SQTUrJzSzLXt4fDdH+bv+pLEtP50XZzYu0W9NFlzLcpRx3qxqfTNPs/dns8FW0t3sPBcHof6Pm6SGaJtFLlNTAhQDfUWM0OGv+GmqtllYK8Qvq9+48GYb7T49Go9XpbLYax/FqGIarYTheDYJg9f7u7jMrjz7RqQk9Kjnzl4wxxjZNs0Tbthbadikva+11NJ1OjuOq8rIRdV0pxriSUi4hhOBedlk6P5Hn+REhJUipmqqqGillq5RqnXOtd9o2DSgpv0M1ISd9ZM5oW9U1MCa8Mh5o1LTWGqgxPl2U5RFrjNd4W2QpSCmX//nGAYDzDiVnNxAh5ITwddHaGiVbZXjr85BCtgtKrWdf5Xm390fviB8rKaVbFLljjDnOuTPGOOec9sEYJd96Lb9jjPEOFTTWEimtcmAFIxYXqapxCYtF+aFvzt07d17s9XrH+r27x7a3t4/1+/2l9RgMBi/0+/1nUZ5n7yopYelUSbDWP0l2uXfLh8ABY/SsFAL9dOsWCsMQRcEeiuMYRVG0tB5JHKNxGCI0GAyej6Lo7GQyOROM9tbG+6O1+zv31oZ7e2thMDoTjcfnxlF0tCgX/uLKeDzujMP9TpIknTiOOwcHB53JZLJEHEWP/AlErNhWQuhB4AAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/0a557f86eacbfbd70c53c9be3d30041d/8ac56/feature-image.webp 240w", "/static/0a557f86eacbfbd70c53c9be3d30041d/d3be9/feature-image.webp 480w", "/static/0a557f86eacbfbd70c53c9be3d30041d/16d1d/feature-image.webp 861w"],
            "sizes": "(max-width: 861px) 100vw, 861px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/0a557f86eacbfbd70c53c9be3d30041d/8ff5a/feature-image.png 240w", "/static/0a557f86eacbfbd70c53c9be3d30041d/e85cb/feature-image.png 480w", "/static/0a557f86eacbfbd70c53c9be3d30041d/e35ec/feature-image.png 861w"],
            "sizes": "(max-width: 861px) 100vw, 861px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/0a557f86eacbfbd70c53c9be3d30041d/e35ec/feature-image.png",
            "alt": "Tux, the Linux mascot",
            "title": "Tux, the Linux mascot",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <h2 {...{
      "id": "content",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#content",
        "aria-label": "content permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Content`}</h2>
    <ul>
      <li parentName="ul">{`📈 Gantt View;`}</li>
      <li parentName="ul">{`👥 Member Contributions;`}</li>
      <li parentName="ul">{`📝 Live Dashboard;`}</li>
      <li parentName="ul">{`🔖 Recent Activity View;`}</li>
      <li parentName="ul">{`and much more 🔥`}</li>
    </ul>
    <br />
    <p><a parentName="p" {...{
        "href": "documentation/getting-started"
      }}>{`Get started now!`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      